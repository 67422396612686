// -- Generics
$border-width: 1px;
$border-radius: 4px;

// -- Text sizes
// -- DEPRECATED -- use shared/font-sizes
$text-size-xxlarge: 2.3em;
$text-size-xlarge: 1.8em;
$text-size-large: 1.65em;
$text-size-medium: 1.45em;
$text-size-small: 1.1em;
$text-size-xsmall: 0.95em;
$text-size-xxsmall: 0.9em;

// -- Page dimensions
$app-min-width: 800px;
$max-page-width: 1024px;
$narrow-page-width: 800px;
$narrow-column-width: 480px;

// -- Element dimensions
$form-width: 558px;
$form-input-height: 40px;
$small-square-button-size: 25px;
$checkbox-size: 18px;
$testStepMaxWidth: 400px;
$appNavigationWidth: 270px;

// -- Screenshot editor things
$screenshotEditorMaxImageWidth: 229px;
$screenshotEditorMaxImageHeight: 158px;

// -- Form vars
$disabledOpacity: 0.5;
