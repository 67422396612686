@use "sass:color";
// -- Palette
//
// These colors should be assigned to meaningful names before being
// referenced outside of this file.
//
$charcoal: #526065;
$mid-charcoal: #414d52; // One too many charcoals.
$dark-charcoal: #343a40;

$faint-gray-blue: #e2e8ef;
$light-gray-blue: #bec9d5;
$gray-blue: #7f94a9;
$dark-gray-blue: #5b727d;
$darker-gray-blue: #3a515d;

$faint-gray: var(--chakra-colors-gray-100);
$light-gray: #e7e7eb;

$faint-blue: var(--chakra-colors-blue-100);
$light-blue: var(--chakra-colors-blue-200);
$bright-blue: #76a9fa; // = chakra blue.400. Cannot use the var() as this color is used in lighten() by sass-loader
$blue: var(--chakra-colors-blue-500);
$dark-blue: var(--chakra-colors-blue-700);

$teal: #59b29e;
$bright-teal: #50e3c2;

$purple: #7757bb;

$faint-green: #dff0d8;
$light-green: #7dbf62;
$bright-green: #80ca45;
$green: #65af2a;
$dark-green: #478b10;

$faint-red: #f2dede;
$light-red: #eb6859;
$bright-red: #f03e3e;
$dark-red: #c92a2a;

$orange: #f6a93b;

$faint-yellow: #fdfdaf;
$light-yellow: #faf0a0;
$bright-yellow: #ffbc50;
$dark-yellow: #857539;

// -- Container colours
$background-black: $dark-charcoal;
$background-gray: $faint-gray;
$background-gray-blue: $faint-gray-blue;
$background-blue: #d8e4ea;
$background-red: $bright-red;
$background-green: $light-green;
$background-bright-blue: $bright-blue;

// -- Button colours
$buttonBgBrand: var(--chakra-colors-brand-primary-400);
$buttonBgBrandHover: var(--chakra-colors-brand-primary-500);
$buttonBgBrandActive: var(--chakra-colors-brand-primary-700);
$buttonBorderBrand: var(--chakra-colors-brand-primary-400);
$buttonBorderBrandHover: var(--chakra-colors-brand-primary-500);
$buttonBorderBrandActive: var(--chakra-colors-brand-primary-700);
$buttonTextBrand: #fff;
$buttonTextBrandHover: #fff;
$buttonTextBrandActive: #fff;
// --
$buttonBgBlue: $bright-blue;
$buttonBgBlueHover: $blue;
$buttonBgBlueActive: $dark-blue;
$buttonBorderBlue: $bright-blue;
$buttonBorderBlueHover: $blue;
$buttonBorderBlueActive: $dark-blue;
$buttonTextBlue: #fff;
$buttonTextBlueHover: #fff;
$buttonTextBlueActive: #fff;
// --
$buttonBgGray: $faint-gray-blue;
$buttonBgGrayHover: $light-gray-blue;
$buttonBgGrayActive: $gray-blue;
$buttonBorderGray: $faint-gray-blue;
$buttonBorderGrayHover: $light-gray-blue;
$buttonBorderGrayActive: $gray-blue;
$buttonTextGray: $charcoal;
$buttonTextGrayHover: $mid-charcoal;
$buttonTextGrayActive: $dark-charcoal;
// --
$buttonBgGreen: $bright-green;
$buttonBgGreenHover: $green;
$buttonBgGreenActive: $dark-green;
$buttonBorderGreen: $bright-green;
$buttonBorderGreenHover: $green;
$buttonBorderGreenActive: $dark-green;
$buttonTextGreen: #fff;
$buttonTextGreenHover: #fff;
$buttonTextGreenActive: #fff;
// --
$buttonBgRed: $light-red;
$buttonBgRedHover: $bright-red;
$buttonBgRedActive: $dark-red;
$buttonBorderRed: $light-red;
$buttonBorderRedHover: $bright-red;
$buttonBorderRedActive: $dark-red;
$buttonTextRed: #fff;
$buttonTextRedHover: #fff;
$buttonTextRedActive: #fff;
// --
$buttonBgGhosted: white;
$buttonBgGhostedHover: white;
$buttonBgGhostedActive: $faint-gray;
$buttonBorderGhosted: $faint-gray-blue;
$buttonBorderGhostedHover: $gray-blue;
$buttonBorderGhostedActive: $gray-blue;
$buttonTextGhosted: $gray-blue;
$buttonTextGhostedHover: $charcoal;
$buttonTextGhostedActive: $charcoal;

// -- Text colours
$text-black-main: $dark-charcoal;
$text-black-sub: #627b93;
$text-purple-sub: #9f90a8;
$text-blue-main: $darker-gray-blue;
$text-main-dark: #434852;
$text-main-mid: $text-black-sub;
$text-main-light: $gray-blue;

$text-gray-light: #aabac1;
$text-gray-mid: #818b96;

$text-gray-main: color.adjust(color.adjust($text-black-sub, $lightness: -20%), $saturation: -5%);

// -- Dark header
$dark-background-link-color: $light-gray-blue;
$dark-background-link-hover-color: white;

// -- "Good" and "Bad" colors
$success-color: $bright-green;
$failure-color: $bright-red;
$warning-color: $faint-yellow;

// -- Test interface
$notice-background-color: $bright-yellow;
$testScreenshotFrameColor: #eef0f4;
$testScreenshotFrameColorHover: color.adjust($testScreenshotFrameColor, $lightness: -1%);
$test-control-button-background-color: $light-gray;
$test-control-button-text-color: $gray-blue;

// -- Hitzones
$hitzone-color: $success-color;

// -- Form
$form-input-background-color: white;
$form-input-text-color: $text-black-main;
$form-input-disabled-text-color: $gray-blue;
$form-input-disabled-border-color: $faint-gray-blue;
$form-input-disabled-background-color: white;
$form-label-text-color: $dark-charcoal;
$form-label-disabled-text-color: $faint-gray-blue;
$form-information-text-color: $gray-blue;
$form-placeholder-text-color: $faint-gray-blue;
$form-border-color: $faint-gray-blue;
$form-border-color-invalid: $failure-color;

$checkbox-selected-check-color: var(--chakra-colors-brand-primary-500);
$checkbox-disabled-check-color: var(--chakra-colors-brand-primary-300);

$form-input-button-text-color: $gray-blue;
$form-input-button-hover-text-color: $charcoal;
$form-input-button-disabled-text-color: $faint-gray-blue;

// -- Light gray box
$gray-box-background-color: $faint-gray;
$gray-box-link-color: $gray-blue;
$gray-box-link-hover-color: $dark-gray-blue;
$gray-box-icon-color: $dark-gray-blue;
$gray-box-title-color: $gray-blue;
$gray-box-default-button-background-color: $light-gray-blue;
$gray-box-default-button-text-color: $dark-charcoal;
$gray-box-text-color: $text-black-main;
$gray-box-light-text-color: $gray-blue;
$gray-box-faint-text-color: $light-gray-blue;

// -- Dropdown
$dropdown-text-color: $text-black-sub;
$dropdown-hover-text-color: $text-black-main;
$dropdown-disabled-text-color: $faint-gray-blue;
$dropdown-arrow-color: $gray-blue;

// -- Buttons
$button-color: $bright-blue;

// -- Link colors
$link-text-color: $blue;
$link-hover-text-color: $dark-blue;

// -- Test section colours
$test-information: $gray-blue;
$test-fivesecond: $bright-blue;
$test-click: $purple;
$test-question: $orange;
$test-navflow: $teal;
// -- TODO[prototypes]: TBC prototype icon colour
$test-prototype: $teal;
$test-preference: $light-red;
$recruitment-youraudience: $bright-teal;

// -- Modal
$modal-header: $bright-yellow;

// -- Test taking interface colours
$container-shadow-color: rgba(0, 0, 0, 0.05);

// -- More colors! these were in 'app-colors.sass'
$heading-color: $text-black-main;
$background-color: #f7f9fb;

$focus-glow: $bright-blue;
$label-color: $heading-color;
