@use "shared/break-points";
@use "shared/font-sizes";
@use "shared/colors";

.main {
  // Position
  position: fixed;
  bottom: 0;

  // Layout
  display: flex;
  justify-content: flex-end;
  align-items: flex-end; // Make sure the footer stays at the bottom in Safari.
  padding: 20px;

  // Appearance
  font-size: font-sizes.$labelFontSize;
  background: #fff;
  white-space: nowrap;
  &,
  &:hover {
    color: colors.$text-main-light;
    text-decoration: none;
  }

  @media (max-width: break-points.$breakLarge), (orientation: portrait) {
    padding: 15px;
    bottom: 0;
    left: 0;
    text-align: center;
    box-shadow: 0 0 0 1px colors.$container-shadow-color;
  }
}
