@use "mixins/form";
@use "shared/break-points";
@use "shared/font-sizes";

.phoneInput {
  // Text
  font-size: font-sizes.$titleFontSize;
  line-height: 1em;

  // Layout
  padding: 0 0 0 10px;

  // Appearance
  @include form.form-border;
  &:focus-within {
    @include form.form-focused;
  }

  // Children
  input {
    padding: 0.5em;
    height: 1.5em;
    background: none;
    border: 0;
    height: 40px;
  }
  select {
    font-size: 0.6666em;
  }

  @include break-points.mobileLayout {
    font-size: font-sizes.$bodyFontSize;
  }
}
