// This file should be kept in sync with javascripts/constants/break-points.ts
// -- Breakpoints
$breakExtraSmall: 450px;
$breakSmall: 700px;
$breakLarge: 800px;

@mixin mobileLayout() {
  @media (max-width: #{$breakLarge - 1px}),
  (orientation: portrait) {
    @content;
  }
}

@mixin desktopLayout() {
  @media (min-width: $breakLarge) and (orientation: landscape) {
    @content;
  }
}
