.blockMarkdown {
  > ol,
  > ul {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  > ul {
    list-style-type: disc;

    ul {
      list-style-type: circle;

      ul {
        list-style-type: square;
      }
    }
  }

  ol,
  ul {
    padding-inline-start: 40px;
  }

  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
    overflow-wrap: anywhere;

    > a {
      overflow-wrap: anywhere;
    }
  }

  // No margin on the top and bottom of the text.
  // Let the parent decide how much space there should be.
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}
