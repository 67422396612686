@use "shared/colors";
@use "shared/font-sizes";

.messageContainer {
  display: flex;
  align-items: center;
  justify-content: left;
}

.icon {
  font-size: 70px;
  color: colors.$faint-gray-blue;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.message {
  max-width: 665px;
  color: colors.$text-main-light;
  font-size: font-sizes.$bodyFontSize;
  margin: 14px 0;

  p {
    margin: 0;
  }
}
