@use "shared/colors";
@use "shared/font-weights";

.h1 {
  font-size: 24px;
  font-weight: font-weights.$mediumFontWeight;
  line-height: 36px;
  color: colors.$darker-gray-blue;
  margin: 0;
  flex: 1;
}

.h2 {
  margin: 0;
  font-size: 18px;
  font-weight: font-weights.$mediumFontWeight;
  color: colors.$darker-gray-blue;
}

.h4 {
  color: colors.$charcoal;
  font-size: 16px;
  font-weight: font-weights.$mediumFontWeight;
  margin: 0;
}
