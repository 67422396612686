@use "shared/colors";
@use "shared/variables";
@use "mixins/placeholder";

$form-element-focused-box-shadow: 0 0 2px 0 rgba(colors.$focus-glow, 0.6);

@mixin form-border {
  border: variables.$border-width solid colors.$form-border-color;
  border-radius: variables.$border-radius;
}

@mixin form-focused {
  box-shadow: $form-element-focused-box-shadow !important;
  border-color: colors.$focus-glow;
}

@mixin form-focusable {
  &:focus {
    @include form-focused;
  }
}
